.sidebar {
  position: relative;
  z-index: 99;
}
.side-bar {
  background: #5b005a !important;
  & .side-bar-span {
    color: #9b679b;
    padding: 10px;
    display: block;
    margin: 10px;
  }
}
.ant-layout-sider-collapsed {
  & .sidebar-label {
    display: none;
  }
  & .icon {
    font-size: 2em;
    margin-left: 4px;
  }
  & input {
    display: none;
  }
}
aside .ant-layout-sider-zero-width-trigger {
  font-size: 30px;
  width: 69px;
  height: 40px;
  background: #5b0c5a !important;
  color: #fff !important;
  border-radius: 0;
  top: 0;
  padding: 5px;
}
aside.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger {
  left: 0px;
}
.gx-layout-sider-scrollbar > div:first-child {
  overflow: visible;
}
@media (max-width: 990px) {
  .side-bar.ant-layout-sider {
    position: absolute;
    height: 100%;
  }
}

@media (min-width: 991px) {
  aside .ant-layout-sider-zero-width-trigger {
    display: none;
  }
}
