.gx-app-nav {
  justify-content: center;
}

.icon {
  font-size: 1.2em;
  transition: 0.5s all;
}

.icon:hover {
  color: #f9f9f9;
}
